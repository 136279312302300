import "./App.css";
import Home from "./routes/Home/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import OurServices from "./routes/OurServices/OurServices";
import AboutUs from "./routes/AboutUs/AboutUs";
import OurVision from "./routes/OurVision/OurVision";
import ContactUs from "./routes/ContactUs/ContactUs";
import Quotations from "./routes/Quotations/Quotations";
import Jobs from "./routes/Jobs/Jobs";
import Gallery from "./routes/Gallery/Gallery";
import ScrollToTop from "./components/Scroller/Scroller";
import Loader from "./components/Loader/Loader";
import { useEffect, useState } from "react";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 5000);
    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" index={true} element={<Home />} />
          <Route path="/our-services" element={<OurServices />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/our-vision" element={<OurVision />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/quotation" element={<Quotations />} />
          <Route path="/jobs" element={<Jobs />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
