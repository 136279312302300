const PaintServices = () => (
  <svg
    height="64px"
    width="64px"
    version="1.1"
    id="_x32_"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    fill="#44b9b9"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <style type="text/css"></style>{" "}
      <g>
        {" "}
        <path
          className="st0"
          d="M200,474.296C200,495.123,216.884,512,237.704,512h2.517c20.82,0,37.704-16.877,37.704-37.704V338.517H200 V474.296z"
        ></path>{" "}
        <path
          className="st0"
          d="M440.784,65.911l-0.028-0.079h-13.198V43.58C427.544,19.508,408.05,0.015,383.978,0H85.618 C61.547,0.015,42.053,19.508,42.039,43.58v78.332c0.014,24.072,19.508,43.565,43.579,43.579h298.36 c24.072-0.014,43.565-19.507,43.579-43.579V97.455h6.988c3.429,0,6.21,2.78,6.21,6.21v7.807v46.089 c0,8.564-5.22,16.292-13.184,19.494l-0.449,0.178l-171.187,82.282l0.021,0.036c-18.516,7.864-30.624,25.989-30.624,46.175v15.75 h29.205v-15.75c0-8.585,5.212-16.3,13.176-19.501l0.456-0.185l171.173-82.275l-0.021-0.036 c18.531-7.864,30.638-25.989,30.638-46.167V96.414C469.954,76.193,459.979,65.925,440.784,65.911z M398.352,121.911 c-0.006,4.007-1.582,7.515-4.214,10.16c-2.645,2.63-6.146,4.207-10.16,4.214H85.618c-4.014-0.007-7.516-1.583-10.161-4.214 c-2.63-2.645-4.206-6.154-4.214-10.16V43.58c0.008-4.014,1.583-7.515,4.214-10.168c2.645-2.624,6.146-4.2,10.161-4.207h298.36 c4.014,0.008,7.515,1.583,10.16,4.207c2.632,2.652,4.207,6.154,4.214,10.168V121.911z"
        ></path>{" "}
      </g>{" "}
    </g>
  </svg>
);
export default PaintServices;
