import "./HomeBanner.css";
import HomeImage from "../../assets/HomeBanner.jpg";
import { Link } from "react-router-dom";

const HomeBanner = () => {
  return (
    <div className="home-banner banner-content-mt">
      <div
        className="banner-main"
        style={{ backgroundImage: `url(${HomeImage})` }}
      >
        <div className="banner-main-content-container">
          <span className="home-banner-content-header">
            Crafting a better future, one home at a time.
          </span>
          <Link to={"/gallery"} className="home-banner-link">
            <span className="home-banner-content-button">Our Work</span>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default HomeBanner;
