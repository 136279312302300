import { Fragment } from "react";
import Navbar from "../Navbar/Navbar";
import "./OurServices.css";
import servicesImage from "../../assets/our-services.jpg";
import Footer from "../../components/Footer/Footer";
import CardHolder from "../../components/CardHolder/CardHolder";
import { useMediaQuery } from "react-responsive";
import { ourServicesData } from "../../data/data";

const OurServices = () => {
  const isPhone = useMediaQuery({ query: "(max-width: 500px)" });
  const isTabletRange = useMediaQuery({ query: "(max-width:1050px)" });
  return (
    <Fragment>
      <Navbar />
      <CardHolder />
      <h1 className="content-main-header">Our Specialtise</h1>
      <div className="our-services-container">
        <div className="our-services-contents">
          {ourServicesData.map((data, i) => (
            <div className="our-services-content-container" key={i}>
              <div className="our-services-r1">{data.logo()}</div>
              <div className="our-services-r2">
                <h2 className="our-services-content-title">{data.title}</h2>
                {!isPhone && (
                  <p className="our-services-content-material">
                    {data.description}
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
        {!isTabletRange && (
          <div className="our-services-image-container">
            <img src={servicesImage} alt="" className="our-services-image" />
          </div>
        )}
      </div>
      <Footer />
    </Fragment>
  );
};
export default OurServices;
