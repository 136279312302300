import customer1 from "../assets/customerAssets/customer1.jpg";
import customer2 from "../assets/customerAssets/customer2.jpg";
import customer3 from "../assets/customerAssets/customer3.jpg";
import customer4 from "../assets/customerAssets/customer4.jpg";
import customer5 from "../assets/customerAssets/customer5.jpg";
import noDisplayCustomer from "../assets/customerAssets/noDisplayCustomer.jpg";
import imran from "../assets/ourTeamAssets/imran.jpg";
import mumtaz from "../assets/ourTeamAssets/mumtaz.jpg";
import qasim from "../assets/ourTeamAssets/Qasim-abbas.jpeg";
import salik from "../assets/ourTeamAssets/Salik-shah.jpeg";
import uzair from "../assets/ourTeamAssets/uzair.jpg";
import asim from "../assets/ourTeamAssets/Asim-Shehzad.jpeg";
import fahim from "../assets/ourTeamAssets/Fahim-yasir.jpeg";
import mehboob from "../assets/ourTeamAssets/Mehboob-Ali.jpeg";
import jammadin from "../assets/ourTeamAssets/Jamma-din.jpeg";
import mansoor from "../assets/ourTeamAssets/Mansoor.jpeg";
import aqib from "../assets/ourTeamAssets/Aqib-hussain.jpeg";
import imran2 from "../assets/ourTeamAssets/M-Imran.jpeg";
import afzal from "../assets/ourTeamAssets/M-Afzal.jpeg";
import qasim2 from "../assets/ourTeamAssets/M-Qasim.jpeg";
import adeel from "../assets/ourTeamAssets/Adeel-basharat.jpeg";
import shabbir from "../assets/ourTeamAssets/M-Shabbir.jpeg";
import sabir from "../assets/ourTeamAssets/M-Sabir.jpeg";
import yassir from "../assets/ourTeamAssets/Yassir-Siraj.jpeg";
import ammad from "../assets/boardOfDirectors/Ammad.jpeg";
import hafeez from "../assets/boardOfDirectors/Abdul-Hafeez.jpeg";
import ihsan from "../assets/boardOfDirectors/Ihsan.jpeg";
import muzammil from "../assets/boardOfDirectors/Muzammil.jpeg";
import waqas from "../assets/boardOfDirectors/Waqas.jpeg";
import collaborativeApproach from "../assets/coreValuesAssets/collaborative-approach.jpeg";
import competitvePricing from "../assets/coreValuesAssets/competitve-pricing.jpeg";
import highRating from "../assets/coreValuesAssets/high-rating.jpg";
import p1 from "../assets/ourPortfolio/p1.jpg";
import p2 from "../assets/ourPortfolio/p2.jpg";
import p3 from "../assets/ourPortfolio/p3.jpg";
import p4 from "../assets/ourPortfolio/p4.jpg";
import p5 from "../assets/ourPortfolio/p5.jpg";
import p6 from "../assets/ourPortfolio/p6.jpg";
import p7 from "../assets/ourPortfolio/p7.jpg";
import p8 from "../assets/ourPortfolio/p8.jpg";
import p9 from "../assets/ourPortfolio/p9.jpg";
import p10 from "../assets/ourPortfolio/p10.jpg";
import p11 from "../assets/ourPortfolio/p11.jpg";
import p12 from "../assets/ourPortfolio/p12.jpg";
import p13 from "../assets/ourPortfolio/p13.jpg";
import p14 from "../assets/ourPortfolio/p14.jpg";
import p15 from "../assets/ourPortfolio/p15.jpg";
import p16 from "../assets/ourPortfolio/p16.jpg";
import p17 from "../assets/ourPortfolio/p17.jpg";
import p18 from "../assets/ourPortfolio/p18.jpg";
import p19 from "../assets/ourPortfolio/p19.jpg";
import p20 from "../assets/ourPortfolio/p20.jpg";
import p21 from "../assets/ourPortfolio/p21.jpg";
import Anch1 from "../assets/Anch-1.jpg";
import Anch2 from "../assets/Anch-2.jpg";
import Anch3 from "../assets/Anch-3.jpg";
import Anch4 from "../assets/Anch-4.jpg";
import Anch5 from "../assets/Anch-5.jpg";
import Anch6 from "../assets/Anch-6.jpg";
import Anch7 from "../assets/Anch-7.jpg";
import Anch8 from "../assets/Anch-8.jpg";
import Anch9 from "../assets/Anch-9.jpg";
import Anch10 from "../assets/Anch-10.jpg";
import Anch11 from "../assets/Anch-11.jpg";
import Anch12 from "../assets/Anch-12.jpg";
import Anch13 from "../assets/Anch-13.jpg";
import Anch14 from "../assets/Anch-14.jpg";
import Anch15 from "../assets/Anch-15.jpg";
import Anch16 from "../assets/Anch-16.jpg";
import Anch17 from "../assets/Anch-17.jpg";
import Anch18 from "../assets/Anch-18.jpg";
import Anch19 from "../assets/Anch-19.jpg";
import Anch20 from "../assets/Anch-20.jpg";
import Anch21 from "../assets/Anch-21.jpg";
import Anch22 from "../assets/Anch-22.jpg";
import Anch23 from "../assets/Anch-23.jpg";
import Anch24 from "../assets/Anch-24.jpg";
import Anch25 from "../assets/Anch-25.jpg";
import CBR1 from "../assets/CBR-1.jpg";
import Kotli1 from "../assets/Kotli-1.jpg";
import Bahria1 from "../assets/Bahria-1.jpg";
import Fazaia1 from "../assets/Fazaia-1.jpg";
import Fazaia2 from "../assets/Fazaia-2.jpg";
import Fazaia3 from "../assets/Fazaia-3.jpg";
import Fazaia4 from "../assets/Fazaia-4.jpg";
import Fazaia5 from "../assets/Fazaia-5.jpg";
import Gulberg1 from "../assets/Gulberg-1.jpg";
import cal1 from "../assets/California-US.jpg";
import hous1 from "../assets/Houstan-US.jpg";
import alr1 from "../assets/Al-Rasheed-Arcade.jpg";
import cap1 from "../assets/Capital-1.jpg";
import RepairServices from "../svg/RepairServices";
import PaintServices from "../svg/PaintServices";
import PlumbingServices from "../svg/PlumbingServices";
import MetalService from "../svg/MetalServices";

export const cardListData = [
  {
    img: "	https://images.unsplash.com/photo-1563166423-a78566af3dbe?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
    title: "Topography, SoilTesting & Related Services",
    description:
      "Our company specializes in topography, soil testing, and related services. We offer comprehensive solutions to assess and analyze landforms, soil composition, and properties. Our team of experts provides accurate data and insights to support various projects, such as construction, environmental assessments, and land development, ensuring optimal outcomes and sustainability.",
  },
  {
    img: "https://images.unsplash.com/photo-1542621334-a254cf47733d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Architectural Design",
    description:
      "We offer innovative and functional design solutions for residential and commercial spaces. Our skilled architects blend creativity with practicality, creating visually appealing and harmonious environments that cater to our clients’ unique needs and preferences. Trust us to transform your vision into reality with architectural excellence.",
  },
  {
    img: "https://images.unsplash.com/photo-1564349358584-6dbcf604e0c7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Structural Drawing",
    description:
      "We design structural drawings that constitue detailed plans and diagrams that provide essential information about the design and construction of a building’s framework, ensuring structural integrity and safety throughout the project.",
  },
  {
    img: "https://images.unsplash.com/photo-1540103711724-ebf833bde8d1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    title: "Construction Service",
    description:
      "We offer comprehensive solutions for residential and commercial projects. With a skilled team and attention to quality, we deliver successful outcomes, from planning and design to execution and project management. Trust us for reliable and efficient construction services that meet your needs and exceed expectations.",
  },
  {
    img: "https://images.unsplash.com/photo-1582417728413-b2347161b864?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mjh8fGxpdmluZyUyMHJvb218ZW58MHx8MHx8fDA%3D",
    title: "Interior Design",
    description:
      "We specialize in crafting exceptional spaces that harmonize functionality and aesthetics. With a focus on client preferences and lifestyle, our expert designers curate personalized interiors, incorporating innovative ideas and premium materials. From concept to completion, we strive to create captivating environments that inspire and elevate everyday living.",
  },
  {
    img: "https://images.unsplash.com/photo-1580810734898-5e1753f23337?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Y29uc3RydWN0aW9uJTIwbWF0ZXJpYWxzfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
    title: "Material Supplies",
    description:
      "We are a reliable provider of a wide range of high-quality construction materials. With a commitment to prompt delivery and competitive prices, we cater to the diverse needs of construction projects, ensuring customer satisfaction and project success.",
  },
  {
    img: "https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YWdyZWVtZW50fGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
    title: "Contracts and Consultancy",
    description:
      "Our company provides professional contract management and consultancy services, offering expert guidance and support to ensure successful project execution and adherence to legal and regulatory requirements.",
  },
  {
    img: "https://images.unsplash.com/photo-1517048676732-d65bc937f952?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjR8fGFncmVlbWVudHxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60",
    title: "Project Management",
    description:
      "We provide professional oversight and coordination for various projects. Our experienced team ensures effective planning, scheduling, and execution, delivering successful outcomes on time and within budget. Trust us to handle the complexities and challenges of your projects with efficiency and expertise.",
  },
  {
    img: "https://images.unsplash.com/photo-1560518883-ce09059eeffa?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aG91c2UlMjBmb3IlMjBzYWxlfGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60",
    title: "Real Estate",
    description:
      "Our company offers a diverse selection of properties, ranging from residential to commercial, for sale or rent. With a focus on customer satisfaction, our experienced agents guide clients through every step of the process, ensuring smooth transactions and finding the perfect property to meet their needs and dreams. Trust us to help you navigate the real estate market with expertise and professionalism.",
  },
];
export const customerReviewData = [
  {
    name: "Mohammad Ali",
    address: "B-17 Islamabad",
    review:
      "AMARC Engineering And Construction Company excels! Precise, innovative, and reliable. Highly recommended! ⭐⭐⭐⭐⭐",
    displayPicture: `${customer1}`,
  },
  {
    name: "Asadullah",
    address: "Block F, Naval Anchorage Islamabad",
    review:
      "I am very pleased with the work. The method used in construction was truly excellent. From the documentation of the work to every step, the approach was very professional. The selection of materials was also very good. The timely completion of the project is a significant success, making it convenient for me. I am entirely satisfied with this construction work and would recommend it to others as well.⭐⭐⭐⭐⭐",
    displayPicture: `${customer2}`,
  },
  {
    name: "Qasim Raza",
    address: "DHA Islamabad",
    review:
      "I am super happy with AMARC Engineering And Construction Company. The way they bring new and unique designs to life is truly remarkable. Their approach to construction is distinct, making them my top choice⭐⭐⭐⭐⭐",
    displayPicture: `${customer3}`,
  },
  {
    name: "Haroon Rashid Sheikh",
    address: "Block F - Naval Anchorage Islamabad",
    review:
      "The timely completion of the house and the excellent quality of materials have fulfilled all my expectations. I am very pleased with the company's work. The efficiency of the work and the professional approach have greatly impressed me. I sincerely thank this construction company and would recommend it to others who are in search of excellent construction services. ⭐⭐⭐⭐⭐",
    displayPicture: `${customer4}`,
  },
  {
    name: "Muhammad Rehan",
    address: "CBR Town Islamabad",
    review:
      "Choosing AMARC Construction Company for my project has proven to be the best decision of my life, Alhamdulillah. Satisfied. ⭐⭐⭐⭐⭐",
    displayPicture: `${noDisplayCustomer}`,
  },
  {
    name: "Ghulam Abbas",
    address: "Bahria Town Islamabad",
    review:
      "AMARC Engineering And Construction Company turns dreams into reality! Their work is excellent, and I am highly satisfied with their services.⭐⭐⭐⭐⭐",
    displayPicture: `${customer5}`,
  },
  {
    name: "Imran Sadiq",
    address: "Bahria Enclave",
    review:
      "The design, layout, and finishing of the house have been done with perfection. I will make them my first choice for the next project as well..⭐⭐⭐⭐⭐",
    displayPicture: `${noDisplayCustomer}`,
  },
];
export const allLinksData = [
  { value: " our services", to: "/our-services" },
  { value: "gallery", to: "/gallery" },
  { value: "about us", to: "/about-us" },
  { value: "our vision", to: "/our-vision" },
  { value: "contact us", to: "/contact-us" },
  { value: "quotation", to: "/quotation" },
  { value: "jobs", to: "/jobs" },
];
export const ourInfoData = [
  {
    title: "Experienced Team",
  },
  {
    title: "Cutting-Edge Technology",
  },
  {
    title: "Timely Completion",
  },
  {
    title: "High-Quality Workmanship",
  },
  {
    title: "Seamless Communication",
  },
  {
    title: "Innovative Solutions",
  },
  {
    title: "Cost-Effective Approach",
  },
  {
    title: "Client-Centric Focus",
  },
  {
    title: "Diverse Portfolio",
  },
  {
    title: "Commitment to Excellence",
  },
];
export const ourTeamData = [
  {
    name: "Yasir Siraj",
    role: "AM Field",
    image: `${yassir}`,
  },
  {
    name: "Asim Shehzad Abbasi",
    role: "AM Field",
    image: `${asim}`,
  },
  {
    name: "Salik Hussain Shah",
    role: "Assitant Construction Manager",
    image: `${salik}`,
  },
  {
    name: "Fahim Yassir",
    role: "AM Planning & QS",
    image: `${fahim}`,
  },
  {
    name: "Qasim Abbas",
    role: "Senior Draftsman",
    image: `${qasim}`,
  },
  {
    name: "Mehboob Ali Shah",
    role: "Field Architect",
    image: `${mehboob}`,
  },
  {
    name: "Rao Imran Maqsood",
    role: "Sr. Site Engineer",
    image: `${imran}`,
  },
  {
    name: "Jamma din",
    role: "Site Engineer",
    image: `${jammadin}`,
  },
  {
    name: "Mansoor Khan",
    role: "Site Engineer",
    image: `${mansoor}`,
  },
  {
    name: "Aqib Hussain",
    role: "Site Engineer",
    image: `${aqib}`,
  },
  {
    name: "Muhammad Imran",
    role: "Foreman (Electrical)",
    image: `${imran2}`,
  },
  {
    name: "Muhammad Afzal",
    role: "Foreman (Plumbing)",
    image: `${afzal}`,
  },
  {
    name: "Muhammad Qasim",
    role: "Foreman (Steel)",
    image: `${qasim2}`,
  },
  {
    name: "Adeel Basharat",
    role: "Sr. Accounts Assistant",
    image: `${adeel}`,
  },
  {
    name: "Muhammad Shabbir",
    role: "Admin Assistant",
    image: `${shabbir}`,
  },
  {
    name: "Muhammad Sabir",
    role: "Account Assistant",
    image: `${sabir}`,
  },
];
export const coreValuesData = [
  {
    title: "innovation",
    description: "We attack complacency and continually improve to create.",
  },
  {
    title: "people",
    description:
      "We aspire to inspire people and offer them development opportunities, and rewarding careers. We build trust and respect through collaboration with our customers, partners, subcontractors. suppliers.",
  },
  {
    title: "quality",
    description:
      "We are passionate about excellence and doing our work the first time around we believe that our reputaion roots form our dependability and delivered values.",
  },
  {
    title: "health & safety",
    description:
      "We are relentless in keeping people and communities safe from harm. We strictly follow all measures to ensure the safety of our team as well as the people around us people around us.",
  },
  {
    title: "integrity",
    description:
      "As an ongoing process, we embed the integrity culture in our practice. We are obligated to conduct our working in an ethical and professional manner.",
  },
];
export const coreValuesImageData = [
  {
    title: "We’ve got an excellent reputation",
    description:
      "Our excellent reputation speaks for itself. We have earned the trust and confidence of our customers through our commitment to quality, integrity, and exceptional service. You can rely on us to deliver top-notch results, backed by our solid reputation in the industry.",
    image: `${highRating}`,
  },
  {
    title: "We offer competitive pricing",
    description:
      "We take pride in providing competitive pricing for our products and services. Our aim is to offer the best value to our customers without compromising on quality. You can trust us to deliver cost-effective solutions without sacrificing excellence.",
    image: `${competitvePricing}`,
  },
  {
    title: "We work with you",
    description:
      "We work closely with you to understand your unique needs and goals. Our collaborative approach ensures that your vision is at the forefront of every decision we make. Together, we create tailor-made solutions to achieve the best outcomes for your project.",
    image: `${collaborativeApproach}`,
  },
];
export const ourPortfolioTypeData = [
  { type: "All" },
  { type: "Build" },
  { type: "Design" },
  { type: "Design & Build" },
  { type: "Interior Design" },
  { type: "Land Development" },
];
export const ourPortfolioData = [
  {
    title: "Mirpur, AJK",
    type: "Design & Build",
    image: `${p1}`,
  },
  {
    title: "Naval Anchorage",
    type: "Design & Build",
    image: `${p2}`,
  },
  {
    title: "Naval Anchorage",
    type: "Design & Build",
    image: `${p3}`,
  },
  {
    title: "Naval Anchorage",
    type: "Design & Build",
    image: `${p4}`,
  },
  {
    title: "Naval Anchorage",
    type: "Design & Build",
    image: `${Anch1}`,
  },
  {
    title: "Naval Anchorage",
    type: "Design & Build",
    image: `${Anch2}`,
  },
  {
    title: "Naval Anchorage",
    type: "Design & Build",
    image: `${Anch3}`,
  },
  {
    title: "Naval Anchorage",
    type: "Design & Build",
    image: `${Anch4}`,
  },
  {
    title: "Naval Anchorage",
    type: "Design & Build",
    image: `${Anch5}`,
  },
  {
    title: "Naval Anchorage",
    type: "Design & Build",
    image: `${Anch6}`,
  },
  {
    title: "Naval Anchorage",
    type: "Design & Build",
    image: `${Anch7}`,
  },
  {
    title: "Naval Anchorage",
    type: "Design & Build",
    image: `${Anch8}`,
  },
  {
    title: "CBR Town, Islamabad",
    type: "Design & Build",
    image: `${CBR1}`,
  },
  {
    title: "Naval Anchorage",
    type: "Design & Build",
    image: `${Anch9}`,
  },
  {
    title: "Kotli AJK",
    type: "Design & Build",
    image: `${Kotli1}`,
  },
  {
    title: "Al-Rasheed Arcade Rawalpindi",
    type: "Design & Build",
    image: `${alr1}`,
  },
  {
    title: "Naval Anchorage",
    type: "Build",
    image: `${Anch10}`,
  },
  {
    title: "Naval Anchorage",
    type: "Build",
    image: `${Anch25}`,
  },
  {
    title: "CBR, Islamabad",
    type: "Build",
    image: `${p5}`,
  },
  {
    title: "Capital Smart City",
    type: "Build",
    image: `${p6}`,
  },
  {
    title: "Capital Smart City",
    type: "Build",
    image: `${p7}`,
  },
  {
    title: "B17, Islamabad",
    type: "Build",
    image: `${p8}`,
  },
  {
    title: "B17, Islamabad",
    type: "Build",
    image: `${p9}`,
  },
  {
    title: "Naval Anchorage",
    type: "Build",
    image: `${Anch11}`,
  },
  {
    title: "Bahria Enclave Islamabad",
    type: "Build",
    image: `${Bahria1}`,
  },
  {
    title: "Naval Anchorage",
    type: "Build",
    image: `${Anch12}`,
  },
  {
    title: "Bahria Complex",
    type: "Design",
    image: `${p10}`,
  },
  {
    title: "Bahria Enclave Islamabad",
    type: "Build",
    image: `${p11}`,
  },
  {
    title: "Capital Smart City Islamabad",
    type: "Build",
    image: `${cap1}`,
  },
  {
    title: "Masjid Bilal, AJK",
    type: "Design",
    image: `${p12}`,
  },
  {
    title: "Naval Anchorage",
    type: "Design",
    image: `${p13}`,
  },
  {
    title: "Naval Anchorage",
    type: "Design",
    image: `${Anch23}`,
  },
  {
    title: "Naval Anchorage",
    type: "Design",
    image: `${Anch24}`,
  },
  {
    title: "Naval Anchorage",
    type: "Design",
    image: `${Anch13}`,
  },
  {
    title: "Naval Anchorage",
    type: "Design",
    image: `${Anch14}`,
  },
  {
    title: "Naval Anchorage",
    type: "Design",
    image: `${Anch15}`,
  },
  {
    title: "Gulberg Greens",
    type: "Design",
    image: `${Gulberg1}`,
  },
  {
    title: "Fazaia Housing Scheme",
    type: "Design",
    image: `${Fazaia2}`,
  },
  {
    title: "Fazaia Housing Scheme",
    type: "Design",
    image: `${Fazaia3}`,
  },
  {
    title: "Fazaia Housing Scheme",
    type: "Design",
    image: `${Fazaia4}`,
  },
  {
    title: "Fazaia Housing Scheme",
    type: "Design",
    image: `${Fazaia1}`,
  },
  {
    title: "Naval Anchorage",
    type: "Design",
    image: `${Anch16}`,
  },
  {
    title: "Naval Anchorage",
    type: "Design",
    image: `${Anch17}`,
  },
  {
    title: "Fazaia Housing Scheme",
    type: "Design",
    image: `${Fazaia5}`,
  },
  {
    title: "Naval Anchorage",
    type: "Design",
    image: `${Anch18}`,
  },
  {
    title: "Naval Anchorage",
    type: "Design",
    image: `${Anch19}`,
  },
  {
    title: "Naval Anchorage",
    type: "Design",
    image: `${Anch22}`,
  },
  {
    title: "Naval Anchorage",
    type: "Design",
    image: `${Anch20}`,
  },
  {
    title: "Naval Anchorage",
    type: "Design",
    image: `${Anch21}`,
  },
  {
    title: "California, USA ",
    type: "Design",
    image: `${cal1}`,
  },
  {
    title: "Houston, USA",
    type: "Design",
    image: `${hous1}`,
  },
  {
    title: "Shadra Farms Islamabad",
    type: "Land Development",
    image: `${p14}`,
  },
  {
    title: "Naval Anchorage",
    type: "Design",
    image: `${p15}`,
  },
  {
    title: "Islamabad",
    type: "Interior Design",
    image: `${p16}`,
  },
  {
    title: "Islamabad",
    type: "Interior Design",
    image: `${p17}`,
  },
  {
    title: "AJK",
    type: "Interior Design",
    image: `${p18}`,
  },
  {
    title: "Naval Anchorage",
    type: "Design",
    image: `${p19}`,
  },
  {
    title: "Naval Anchorage",
    type: "Design",
    image: `${p20}`,
  },
  {
    title: "Naval Anchorage",
    type: "Design",
    image: `${p21}`,
  },
];
export const constructionStepDetailsData = [
  {
    title: "Step 1 – Site Visit",
    description:
      "After an initial consultation over the phone, we will conduct a site visit to assess the feasibility of the project. We will discuss your needs, goals, parameters, constraints, create a detailed work plan and define a timeline for project completion.",
  },
  {
    title: "Step 2 – Design Coordination",
    description:
      "We will translate your idea into accurate design drawings that will illustrate how the final product will turn out.",
  },
  {
    title: " Step 3 – Permitting",
    description:
      "If your construction project requires a permit from the Capital Development Authority (CDA) or any other related development authority, we will work with you to achieve it. We will coordinate with the concerned officials on your behalf to expedite the process.",
  },
  {
    title: "Step 4 – Material Selection",
    description:
      "Following permit acquisition, we will help you choose quality materials that complement your style and suit your budget.",
  },
  {
    title: " Step 5 – Project Execution",
    description:
      "This is where we begin the actual construction turn the concept into reality. We will keep you in the loop on everything during the process to ensure that the result reflects your imagination.",
  },
  {
    title: "Step 6 – Project Close-Out",
    description:
      "Once the construction is completed, we will walk you through it and immediately address any issues you may have and make sure that you are delighted with the outcome before the final handshake.",
  },
];
export const ourServicesData = [
  {
    logo: RepairServices,
    title: "Repair Services",
    description:
      "When it comes to repair services, our team is your trusted partner for all your needs. We understand that unexpected breakdowns damages can disrupt your daily life, which is why we are committed to providing swift and reliable solutions. Our skilled technicians are equipped with the latest tools and expertise to tackle a wide range of repairs, including electrical, plumbing, HVAC, appliance repairs, and more. Whether it's a minor fix or a major overhaul, you can count on us to deliver top-notch service that restores your peace of mind and the functionality of your home or business. Don't let repairs hold you back; let us take care of them efficiently and effectively.",
  },
  {
    logo: PaintServices,
    title: "Wall Painting",
    description:
      "When it comes to wall painting services, our team is your go-to choice for transforming your living spaces into vibrant and inviting environments. We understand the power of color and its impact on your surroundings, which is why we are dedicated to delivering exceptional painting solutions. Our experienced painters are skilled in selecting the perfect hues, preparing surfaces meticulously, and applying coats with precision. Whether you're looking to refresh a room with a new color or give your entire property a makeover, we've got you covered. From residential to commercial projects, we take pride in providing professional, clean, and timely painting services that breathe new life into your spaces. Let us bring your vision to life and enhance the beauty of your surroundings through the art of painting.",
  },
  {
    logo: PlumbingServices,
    title: "Creative Plumbing",
    description:
      "When it comes to creative plumbing services, our team is your innovative partner for all your plumbing needs. We understand that plumbing isn't just about fixing leaks and unclogging drains; it's about designing efficient and creative solutions for your unique requirements. Our skilled plumbers are well-versed in thinking outside the box to tackle complex plumbing challenges. Whether you're looking to create a custom water feature, install a state-of-the-art irrigation system, or design a unique bathroom layout, we've got the expertise and creativity to make it happen. We take pride in turning your plumbing dreams into reality, all while ensuring functionality and efficiency. Don't settle for ordinary plumbing – let us bring a touch of creativity to your water-related projects and ensure they flow seamlessly.",
  },
  {
    logo: MetalService,
    title: "Metal Roofing",
    description:
      "When it comes to metal roofing services, our team is your reliable choice for durable and stylish roofing solutions. We understand the importance of a roof that not only provides protection but also enhances the aesthetics of your property. Our experienced roofers specialize in metal roofing, offering a wide range of options to suit your needs. Whether you're looking for the classic elegance of standing seam metal or the rugged durability of corrugated roofing, we've got you covered. Our meticulous installation ensures longevity and weather resistance, making your investment in metal roofing a smart choice. Say goodbye to frequent roof repairs and hello to a sleek and modern look that adds value to your home or business. Let us elevate your property with our expert metal roofing services.",
  },
];

export const advisoryBoard = [
  {
    name: "Ammad Saleem",
    role: "COO",
    image: `${ammad}`,
  },
  {
    name: "Abdul Hafeez Anjum",
    role: "Director Business Development",
    image: `${hafeez}`,
  },
  {
    name: "Muzammil Rasheed",
    role: "Director Business Development",
    image: `${muzammil}`,
  },
];
export const leadershipTeam = [
  {
    name: "Engr. Waqas Roshan",
    role: "CEO",
    image: `${waqas}`,
  },
  {
    name: "M. Uzair Qureshi",
    role: "GM COMMERCIALS & PLANNING",
    image: `${uzair}`,
  },
  {
    name: "Ammad Saleem",
    role: "COO",
    image: `${ammad}`,
  },
  {
    name: "Ihsan Ullah",
    role: "CHIEF ARCHITECT",
    image: `${ihsan}`,
  },
  {
    name: "Engineer Mumtaz Memon",
    role: "GM COMMERCIALS & PLANNING",
    image: `${mumtaz}`,
  },
];
export const ceoData = [
  {
    name: "Engr. Waqas Roshan",
    role: "CEO",
    image: `${waqas}`,
  },
];
