import { Fragment } from "react";
import "./OurVision.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import ourVisionHouse from "../../assets/our-vision.jpg";
import ceoImage from "../../assets/ceo-message.jpeg";
import Banner from "../../components/Banner/Banner";
import { constructionStepDetailsData } from "../../data/data";
import { useMediaQuery } from "react-responsive";

const OurVision = () => {
  const isPhoneRange = useMediaQuery({ query: "(max-width:540px)" });
  const isTabletRange = useMediaQuery({ query: "(max-width:900px)" });
  return (
    <Fragment>
      <Navbar />
      <div className="our-vision-container banner-content-mt">
        <div
          className="banner-main"
          style={{ backgroundImage: `url(${ourVisionHouse})` }}
        >
          <div className="banner-main-content-container">
            <h1 className="our-vision-image-title">our vision</h1>
            <span className="our-vision-image-description">
              {isPhoneRange
                ? "HELPING PEOPLE BUILD LASTING MEMORIES AND LEGACIES"
                : "OUR MISSION IS TO BUILD INFRASTRUCTURE THAT CAN HELP PEOPLE BUILD LASTING MEMORIES AND LEGACIES"}
            </span>
          </div>
        </div>
        <div className="our-vision-content-container">
          <div className="ceo-message-container">
            <h1 className="content-main-header">ceo's message</h1>
            <div className="ceo-message-content-container">
              {isTabletRange && (
                <img src={ceoImage} alt="" className="ceo-message-image" />
              )}
              <p className="ceo-message">
                Since its inception in 2017, AMARC Engineering & Construction
                (PVT) Limited Company is committed towards shaping the future
                where excellence in construction enabled us achieving highest
                standards of partner’ satisfaction. While functioning on EPC
                company model, commitment to deliver on turnkey solutions and
                meeting the partner’s expectations is indeed embodied in our
                professional traits. At AMARC, our priority is always comfort
                and peace of mind of our partners. Our success is not measured
                solely in financial terms but also in the lasting relationship
                with our partners. We believe in prioritizing partner’s / client
                needs which we consider a key to our successful business
                development and goodwill. In fact, our goal is to lay positive
                socio-economic impact on the communities; we serve. Our human
                resource comprising of skilled professionals and dedicated
                laborers, works tirelessly to uphold the principles of quality,
                craftsmanship, time management and adherence to best engineering
                protocols. This collective commitment ensures that every project
                we undertake reflects our unwavering dedication towards
                excellence. As we navigate towards future, we remain steadfast
                in our commitments to innovation, sustainability, superior
                quality and exploring new business avenues, through mutual
                respect and goodwill. Trust in AMARC would be an honor for all
                of its team members. Indeed, we will continue to uphold our
                traditions of commitments, mutual respect and your entire
                satisfaction.
              </p>
              {!isTabletRange && (
                <img src={ceoImage} alt="" className="ceo-message-image" />
              )}
            </div>
          </div>
          <div className="business-approach-container">
            <h1 className="content-main-header">business approach</h1>
            <p className="business-approach-content">
              Distinguished by years of experience, innovation, and a highly
              capable team, our company is committed to delivering integrated
              solutions. Our approach is characterized by a structured
              methodology, ensuring the efficient delivery of products and
              services. Emphasizing innovation, we excel at providing effective
              engineered solutions to overcome challenges. Central to our
              operations is an integrated quality management system that
              permeates every aspect, empowering our employees to embody our
              corporate values.
            </p>
          </div>
          {!isPhoneRange && (
            <div className="philosophy-container">
              <h1 className="content-main-header">philosophy</h1>
              <div className="philosophies">
                <div className="project-involvement-container">
                  <h2 className="content-main-header">our process</h2>
                  <div className="project-involvement-content">
                    <p>
                      AMARC Engineering & Construction Company has crafted a
                      primary team consisting of highly qualified individuals
                      with a large collective skills capacity oversees civil and
                      multi-disciplinary projects as well as general buildings,
                      housing and property development projects.
                    </p>
                    <p>
                      This enables us to follow a strong integrated approach to
                      ensure quality outcome. Furthermore, through the grasps of
                      our integrated primary team we work in association with
                      architects, quantity surveyors, civil and structural
                      engineers, project managers and other specialists.
                      <br /> Our process includes the following:
                    </p>
                    <ul>
                      <li>Project Insight</li>
                      <li>Architectural Design</li>
                      <li>Structural Design</li>
                      <li>Approvals</li>
                      <li>Project Analysis</li>
                      <li>Planning</li>
                      <li>Procurement</li>
                      <li>Execution</li>
                      <li>Delivery</li>
                      <li>Post Delivery</li>
                    </ul>
                  </div>
                </div>
                <div className="cost-strategy-container">
                  <h2 className="content-main-header">Our Services</h2>
                  <div className="cost-strategy-content">
                    <p>
                      AMARC Engineering & Construction Company is a leading
                      provider of comprehensive construction services,
                      delivering excellence in every project. Our diverse range
                      of services encompasses the entire construction process,
                      from initial planning and design to project management and
                      execution. With a team of highly skilled architects,
                      engineers, and construction professionals, we specialize
                      in residential, commercial, and industrial construction
                      projects.
                    </p>
                    <p>
                      We are committed to exceeding client expectations and
                      building lasting structures that stand the test of time.
                      We provide the following services:
                    </p>
                    <ul>
                      <li>Contracts & Consultancy</li>
                      <li>Project Management</li>
                      <li>Topography & Soil Testing</li>
                      <li>Construction Services</li>
                      <li>Architectural Drawings</li>
                      <li>Grey Structure / Finishing</li>
                      <li>Structural Design</li>
                      <li>Material Supplies</li>
                      <li>Animation & Interior Design</li>
                      <li>Maintenance & Renovations</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
          <Banner />
          <div className="construction-process-container">
            <h1 className="content-main-header">Our construction process</h1>
            <div className="construction-process-steps-container">
              <p className="construction-process-intro">
                Our ultimate goal is to make your construction project as
                enjoyable and pleasurable as possible for you. We understand all
                the common pitfalls that happen before or during any phase and
                proactively resolve them before they occur so that your project
                stays right on track from beginning to end.
              </p>
              <div className="construction-process-steps">
                {constructionStepDetailsData.map((detail, i) => (
                  <div className="construction-detail-container" key={i}>
                    <ul className="construction-detail-title-list">
                      <li className="construction-detail-title-list-item">
                        {detail.title}
                      </li>
                    </ul>
                    <span className="construction-detail-content">
                      {detail.description}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default OurVision;
