import { Fragment } from "react";
import "./Gallery.css";
import Navbar from "../Navbar/Navbar.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import { useState } from "react";
import { ourPortfolioTypeData } from "../../data/data.js";
import { ourPortfolioData } from "../../data/data.js";

const Gallery = () => {
  const [activeTab, setActiveTab] = useState("All");
  const [activeType, setActiveType] = useState("All");

  const onClick = (type) => {
    setActiveTab(type);
    if (activeType === type) {
      setActiveType(null);
    } else {
      setActiveType(type);
    }
  };
  const AllDataTypes = () => {
    return ourPortfolioData.map((data, i) => (
      <div
        key={i}
        className="portfolio-image-container"
        style={{ backgroundImage: `url(${data.image})` }}
      >
        <div className="portfolio-image-info">
          <span className="portfolio-location">{data.title}</span>
          <span className="portfolio-type">{data.type}</span>
        </div>
      </div>
    ));
  };
  const FilteredDataTypes = (dataType) => {
    const filteredTypes = ourPortfolioData.filter((data) => {
      return data.type === dataType;
    });
    return filteredTypes.map((data, i) => (
      <div
        key={i}
        className="portfolio-image-container"
        style={{ backgroundImage: `url(${data.image})` }}
      >
        <div className="portfolio-image-info">
          <span className="portfolio-location">{data.title}</span>
          <span className="portfolio-type">{data.type}</span>
        </div>
      </div>
    ));
  };
  return (
    <Fragment>
      <Navbar />
      <div className="gallery-container">
        <h1 className="content-main-header">our portfolio</h1>
        <p className="our-portfolio-content">
          Our portfolio includes a curated collection of our finest works and
          successful projects. From stunning architectural designs to intricate
          interior spaces, our portfolio showcases our expertise, innovation,
          and commitment to delivering exceptional results. Explore our body of
          work and witness the embodiment of our passion for design and
          construction excellence
        </p>
        <div className="our-portfolio-gallery">
          <div className="our-portfolio-navbar">
            {ourPortfolioTypeData.map((data, i) => (
              <span
                className={`our-portfolio-type ${
                  activeType === data.type ? "active" : ""
                }`}
                key={i}
                onClick={() => onClick(data.type)}
              >
                {data.type}
              </span>
            ))}
          </div>
          <div className="our-portfolio-images-container">
            {activeTab === "All"
              ? AllDataTypes()
              : FilteredDataTypes(activeTab)}
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};
export default Gallery;
