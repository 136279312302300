import "./Quotations.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Fragment } from "react";
import quotation1 from "../../assets/General-Quotation-1.pdf";
import quotation2 from "../../assets/General-Quotation-2.pdf";

const Quotations = () => {
  return (
    <Fragment>
      <Navbar />
      <div className="quotation-container">
        <h1 className="content-main-header">general quotation</h1>
        <div className="quotation-content-container">
          <span className="quotation-content-description">
            EPC Model For Commercial & Residential Buildings
          </span>
          <div className="quotation-content">
            <a
              href={quotation1}
              className="quotation-link"
              target="_blank"
              rel="noreferrer"
            >
              General Quotation A
            </a>
            <a
              href={quotation2}
              className="quotation-link"
              target="_blank"
              rel="noreferrer"
            >
              General Quotation B
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};
export default Quotations;
