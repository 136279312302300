import "./CustomerReviews.css";
import Marquee from "react-fast-marquee";
import { customerReviewData } from "../../data/data";
import LocationMarker from "../../svg/LocationMarker";

const CustomerReviews = () => {
  return (
    <div className="customers-review-container">
      <h1 className="content-main-header">what others say about us</h1>
      <div className="customer-review-container">
        <Marquee>
          {customerReviewData.map((data, i) => (
            <div className="customer-card-container" key={i}>
              <img
                src={data.displayPicture}
                alt=""
                className="customer-card-image"
              />
              <span className="customer-name">{data.name}</span>
              <div className="customer-address-container">
                <LocationMarker />
                <span className="customer-address">{data.address}</span>
              </div>
              <p className="customer-review-content">{data.review}</p>
            </div>
          ))}
        </Marquee>
      </div>
    </div>
  );
};
export default CustomerReviews;
