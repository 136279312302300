const Menu = ({ onClick }) => (
  <svg
    fill="#000000"
    width="30px"
    height="30px"
    viewBox="0 0 32 32"
    enableBackground="new 0 0 32 32"
    id="Glyph"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <path
        d="M26,16c0,1.104-0.896,2-2,2H8c-1.104,0-2-0.896-2-2s0.896-2,2-2h16C25.104,14,26,14.896,26,16z"
        id="XMLID_314_"
      ></path>
      <path
        d="M26,8c0,1.104-0.896,2-2,2H8c-1.104,0-2-0.896-2-2s0.896-2,2-2h16C25.104,6,26,6.896,26,8z"
        id="XMLID_315_"
      ></path>
      <path
        d="M26,24c0,1.104-0.896,2-2,2H8c-1.104,0-2-0.896-2-2s0.896-2,2-2h16C25.104,22,26,22.896,26,24z"
        id="XMLID_316_"
      ></path>
    </g>
  </svg>
);
export default Menu;
