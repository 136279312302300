import "./Footer.css";
import { Fragment } from "react";
import footerLogo from "../../assets/footer-logo-removebg-preview.png";
import { Link } from "react-router-dom";
import { allLinksData } from "../../data/data";
import Github from "../../svg/Github";
import LinkedIn from "../../svg/LinkedIn";
import HeadOffice from "../../svg/HeadOffice";
import ContactNumber from "../../svg/ContactNumber";
import Email from "../../svg/Email";
import Facebook from "../../svg/Facebook";
import Instagram from "../../svg/Instagram";
import LinkedIn2 from "../../svg/LinkedIn2";
import { useMediaQuery } from "react-responsive";

const Footer = () => {
  const isPhoneRange = useMediaQuery({ query: "(max-width:600px)" });
  return (
    <Fragment>
      <div className="footer-container">
        <div className="footer-logo">
          <Link to={"/"}>
            <img src={footerLogo} alt="logo" className="footer-company-logo" />
          </Link>
          <span className="footer-logo-text">
            Have your site structure developed by a certified and professional
            group of civil engineers.
          </span>
          <div className="footer-contacts">
            <div className="contact-container">
              <div className="contact-title">
                <HeadOffice />
                <span className="footer-contact-title-content">
                  Head office
                </span>
              </div>
              <a
                href="https://www.google.com/maps/place/AMARC+Engineering+%26+Construction+Company/@33.5691552,73.1881671,17z/data=!4m10!1m2!2m1!1sGround+Floor,+Plot+No+59,+Commercial+1,+Naval+Anchorage+Islamabad!3m6!1s0x38dfefb93514756b:0x4cf7c43fb4ca16ee!8m2!3d33.5691552!4d73.1926732!15sCkFHcm91bmQgRmxvb3IsIFBsb3QgTm8gNTksIENvbW1lcmNpYWwgMSwgTmF2YWwgQW5jaG9yYWdlIElzbGFtYWJhZJIBFGNvbnN0cnVjdGlvbl9jb21wYW554AEA!16s%2Fg%2F11pfq8nvxs?entry=ttu"
                className="contact-link"
                target="_blank"
                rel="noreferrer"
              >
                Ground Floor, Plot No 59, Commercial 1, Naval Anchorage
                Islamabad
              </a>
            </div>
            <div className="contact-container">
              <div className="contact-title">
                <ContactNumber />
                <span className="footer-contact-title-content">
                  Contact number
                </span>
              </div>
              <span>+92(300) 289 1288, +92(345) 560 6814</span>
            </div>
            <div className="contact-container">
              <div className="contact-title">
                <Email />
                <span className="footer-contact-title-content">Email</span>
              </div>
              <span>info@amarcengineering.co</span>
            </div>
          </div>
        </div>
        <div className="footer-links">
          {allLinksData.map((link, i) => (
            <Link key={i} className="footer-link" to={link.to}>
              {link.value}
            </Link>
          ))}
        </div>
        {!isPhoneRange ? (
          <div className="footer-socials-large">
            <a
              href="https://www.facebook.com/p/AMARC-Engineering-Construction-Company-100071666932889/"
              target="_blank"
              className="footer-link"
              rel="_no noreferrer"
            >
              Facebook
            </a>
            <a
              href="https://www.instagram.com/amarc.engc/"
              target="_blank"
              className="footer-link"
              rel="noreferrer"
            >
              Instagram
            </a>
            <a
              href="https://www.linkedin.com/company/amarcengineeringandconstructioncompany/?originalSubdomain=pk"
              target="_blank"
              className="footer-link"
              rel="noreferrer"
            >
              LinkedIn
            </a>
          </div>
        ) : (
          <div className="footer-socials-small">
            <a
              href="https://www.facebook.com/p/AMARC-Engineering-Construction-Company-100071666932889/"
              target="_blank"
              className="footer-link-small"
              rel="_no noreferrer"
            >
              <Facebook />
            </a>
            <a
              href="https://www.instagram.com/amarc.engc/"
              target="_blank"
              className="footer-link-small"
              rel="noreferrer"
            >
              <Instagram />
            </a>
            <a
              href="https://www.linkedin.com/company/amarcengineeringandconstructioncompany/?originalSubdomain=pk"
              target="_blank"
              className="footer-link-small"
              rel="noreferrer"
            >
              <LinkedIn2 />
            </a>
          </div>
        )}
      </div>
      <div className="footer-copyrights">
        <div className="copyrights-content-container">
          <span className="copyright-content">
            &#169; 2023 AMARC Engineering and Construction Company. All Rights
            Reserved.
          </span>
          <div className="credits-bar">
            <span className="copyright-content">
              Designed and Created for AMARC Engineering and Construction
              Company by Malik Sharoz Tariq
            </span>
            <div className="credits-logo">
              <a
                className="credit-logo linkedin"
                href="https://www.linkedin.com/in/malik-sharoz-tariq-542562272/"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedIn />
              </a>
              <a
                className="credit-logo github"
                href="https://github.com/Sharoz29"
                target="_blank"
                rel="noreferrer"
              >
                <Github />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Footer;
