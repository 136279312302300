import "./AboutUs.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Fragment } from "react";
import handShake from "../../assets/hanshaking.jpg";
import { Link } from "react-router-dom";
import OurTeam from "../../components/OurTeam/OurTeam";
import { coreValuesData } from "../../data/data";
import { coreValuesImageData } from "../../data/data";
import { useMediaQuery } from "react-responsive";
import OurAdvisoryBoard from "../../components/OurAdvisoryBoard/OurAdvisoryBoard";
import CEOContainer from "../../components/CEOContainer/CEOContainer";
import LeadershipContainer from "../../components/Leadership/Leadership";

const AboutUs = () => {
  const isPhoneRange = useMediaQuery({ query: "(max-width:540px)" });
  return (
    <Fragment>
      <Navbar />
      <div className="about-us-container">
        <div className="about-us-container-content">
          <h1 className="content-main-header">About our company</h1>
          <p className="about-us-content">
            Established in 2017, AMARC Engineering & Construction Company, duly
            registered with the Pakistan Engineering Council (PEC) is a dynamic
            player in the construction industry of Pakistan. Specialized in
            Engineering, Procurement, and Construction (EPC model), the company
            offers comprehensive approach towards project development. AMARC
            success is rooted in the expertise of its exceedingly skilled
            professionals, encompassing highly qualified architect, structural,
            civil, electrical and mechanical engineers. While cognizant to
            proficiency in every facet of construction processes, our
            accomplished teams ensure highest industry standards during all
            phases of the project development. Our dedicated workforce,
            proficient in steelwork, framework and plumbing, complementing to
            engineering excellence, enables us to offer end-toend turnkey
            solutions. While committed to excellence, AMARC adheres to all
            engineering protocols, paying focus attention to even minor details
            and unwavering commitments to quality make us as a trusted partner
            in the project development from ground to sky. In parallel ensuring
            safeties is always our paramount priority; with a focus on
            well-being of our team members, partners, and communities.
            Implementation of rigorous safety controls, industry-driven best
            practices, and continuous training of our human resource is the haul
            mark of our successful project delivery.
          </p>
        </div>
        <div className="about-us-container-content">
          <h1 className="content-main-header">core values</h1>
          <div className="about-us-content">
            <p>
              AMARC Engineering & Construction Company’s ongoing success and
              development lies in preserving the trust of our customers,
              employees, shareholders and other third parties. It is essential
              that we operate in a manner consistent with our values, in
              compliance with our ethical principles, and the law. To achieve
              that, five basic core values guide our behavior in all aspects of
              our business activity throughout the Pakistan
            </p>

            <span>Our core values are as follows:-</span>
            <div className="core-values-container">
              {coreValuesData.map((data, i) => (
                <div className="core-value-container" key={i}>
                  <h3 className="core-value-title">{data.title}</h3>
                  <span className="core-value-description">
                    {data.description}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="core-values-images-container">
          {coreValuesImageData.map((data, i) => (
            <div className="core-image-data" key={i}>
              <img
                className="core-values-image"
                src={data.image}
                alt={data.title}
              />
              <span className="core-values-image-data-title">{data.title}</span>
              <span className="core-values-image-description">
                {data.description}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="banner2" style={{ backgroundImage: `url(${handShake})` }}>
        <span className="banner-2-header">
          {isPhoneRange
            ? "HELPING PEOPLE BUILD LASTING MEMORIES AND LEGACIES"
            : "OUR MISSION IS TO BUILD INFRASTRUCTURE THAT CAN HELP PEOPLE BUILD LASTING MEMORIES AND LEGACIES"}
        </span>
        <Link to={"/contact-us"} className="banner-link">
          <span className="banner-link-content">Contact Us</span>
        </Link>
      </div>
      <h1 className="content-main-header" style={{ marginBottom: 50 }}>
        Our Advisory Board
      </h1>
      <div className="our-advisory">
        <CEOContainer />
        <OurAdvisoryBoard />
      </div>
      <LeadershipContainer />
      <OurTeam />
      <Footer />
    </Fragment>
  );
};
export default AboutUs;
