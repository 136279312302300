import "./OurTeam.css";
import { ourTeamData } from "../../data/data";

const OurTeam = () => {
  return (
    <div className="our-team-container">
      <h1 className="content-main-header">our team</h1>
      <span className="our-team-info">
        Our team comprises of highly skilled and dedicated professionals,
        committed to delivering excellence in every project.
      </span>
      <div className="our-team-content-container">
        {ourTeamData.map((data, i) => (
          <div className="team-member-container" key={i}>
            <img
              src={data.image}
              alt={data.name}
              className="team-member-image"
            />
            <div className="team-member-info">
              <h3 className="team-member-name">{data.name}</h3>
              <span className="team-member-role">{data.role}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default OurTeam;
