import "./NavbarRegular.css";
import { Link } from "react-router-dom";
import { useState, useEffect, Fragment } from "react";
import { allLinksData } from "../../data/data";
import logo from "../../assets/AMARC-log.jpg";
import Menu from "../../svg/Menu";

export default function NavbarRegular() {
  const [isVisible, setIsVisible] = useState(false);
  const handleClick = () => {
    setIsVisible(!isVisible);
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY !== 0 || isVisible === true) {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
  });
  return (
    <Fragment>
      <div className="navbar-menu">
        <Link to={"/"}>
          <img src={logo} alt="logo" className="navbar-logo" />
        </Link>
        <Menu onClick={handleClick} />
      </div>
      {isVisible && (
        <div className="navbar-menu-dropdown">
          {allLinksData.map((link, i) => (
            <Link key={i} className={`navbar-menu-link`} to={link.to}>
              {link.value}
            </Link>
          ))}
        </div>
      )}
    </Fragment>
  );
}
