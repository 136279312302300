import { advisoryBoard } from "../../data/data";
import "./OurAdvisoryBoard.css";

const OurAdvisoryBoard = () => {
  return (
    <div className="our-advisory-content-container">
      {advisoryBoard.map((data, i) => (
        <div className="team-member-container" key={i}>
          <img src={data.image} alt={data.name} className="team-member-image" />
          <div className="team-member-info">
            <h3 className="team-member-name">{data.name}</h3>
            <span className="team-member-role">{data.role}</span>
          </div>
        </div>
      ))}
    </div>
  );
};
export default OurAdvisoryBoard;
