import { Outlet } from "react-router-dom";
import { Fragment } from "react";
import { useMediaQuery } from "react-responsive";
import NavbarLarge from "../../components/Navbar-Large/NavbarLarge";
import NavbarRegular from "../../components/Navbar-Regular/NavbarRegular";

const Navbar = () => {
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1020px)" });
  const isMobileOrTablet = useMediaQuery({ query: "(max-width: 1019px)" });
  return (
    <Fragment>
      {isDesktopOrLaptop && <NavbarLarge />}
      {isMobileOrTablet && <NavbarRegular />}
      <Outlet />
    </Fragment>
  );
};
export default Navbar;
