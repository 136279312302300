import Banner from "../../components/Banner/Banner";
import OurInfo from "../../components/OurInfo/OurInfo";
import CustomerReviews from "../../components/CustomerReviews/CustomerReviews";
import Footer from "../../components/Footer/Footer";
import Navbar from "../Navbar/Navbar";
import HomeBanner from "../../components/HomeBanner/HomeBanner";

const Home = () => {
  return (
    <div>
      <Navbar />
      <HomeBanner />
      <Banner />
      <OurInfo />
      <CustomerReviews />
      <Footer />
    </div>
  );
};
export default Home;
