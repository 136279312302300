import { leadershipTeam } from "../../data/data";
import { Fragment } from "react";

const LeadershipContainer = () => {
  return (
    <Fragment>
      <h1 className="content-main-header">our leadership team</h1>

      <div className="our-team-content-container">
        {leadershipTeam.map((data, i) => (
          <div className="team-member-container" key={i}>
            <img
              src={data.image}
              alt={data.name}
              className="team-member-image"
            />
            <div className="team-member-info">
              <h3 className="team-member-name">{data.name}</h3>
              <span className="team-member-role">{data.role}</span>
            </div>
          </div>
        ))}
      </div>
    </Fragment>
  );
};
export default LeadershipContainer;
