import "./OurInfo.css";
import teamPhoto from "../../assets/amarc-team.jpg";
import { ourInfoData } from "../../data/data";

const OurInfo = () => {
  return (
    <div className="our-info-intro">
      <h1 className="content-main-header">why choose us?</h1>
      <div className="our-info-content-container">
        <img src={teamPhoto} alt="team" className="our-info-team-image" />
        <div className="our-info-container">
          {ourInfoData.map((data, i) => (
            <div className="our-info-contents" key={i}>
              <div className="our-info-dropdown-select">
                <span className="our-info-header">{data.title}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default OurInfo;
