import BannerImage from "../../assets/construction-websites-banner-scaled.jpg";
import "./Banner.css";

const Banner = () => {
  return (
    <div
      className="parallax"
      style={{ backgroundImage: `url(${BannerImage})` }}
    >
      <div className="banner-contents-container">
        <div className="banner-content-container">
          <span className="banner-content">75</span>
          <span className="banner-content-title">projects completed</span>
        </div>
        <div className="banner-content-container">
          <span className="banner-content">70+</span>
          <span className="banner-content-title">happy clients</span>
        </div>
        <div className="banner-content-container">
          <span className="banner-content">133</span>
          <span className="banner-content-title">workers employed</span>
        </div>
        <div className="banner-content-container">
          <span className="banner-content">15</span>
          <span className="banner-content-title">on going projects</span>
        </div>
      </div>
    </div>
  );
};
export default Banner;
