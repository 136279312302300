import "./Card.css";
import { useMediaQuery } from "react-responsive";

const Card = ({ id, title, imageUrl, description }) => {
  const isPhone = useMediaQuery({ query: "(max-width: 500px)" });
  return (
    <div key={id} className="card-container">
      <div
        className="card-image-container"
        style={{ backgroundImage: `url(${imageUrl})` }}
      >
        <div className="card-text-container">
          <h4 className="card-title">{title}</h4>
          {!isPhone && <p className="card-description">{description}</p>}
        </div>
      </div>
    </div>
  );
};
export default Card;
