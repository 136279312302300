import "./NavbarLarge.css";
import { Link } from "react-router-dom";
import { allLinksData } from "../../data/data";
import logo from "../../assets/AMARC-log.jpg";

export default function NavbarLarge() {
  return (
    <div className="navbar">
      <Link to={"/"}>
        <img src={logo} alt="logo" className="navbar-logo" />
      </Link>
      {allLinksData.map((link, i) => (
        <Link key={i} className={`navbar-link`} to={link.to}>
          {link.value}
        </Link>
      ))}
    </div>
  );
}
