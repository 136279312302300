import "./Jobs.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { Fragment } from "react";
import constructionJob from "../../assets/constructionjob.jpg";
import architect from "../../assets/architect.jpg";
import civilEngineer from "../../assets/civil-engineer.jpg";

const Jobs = () => {
  return (
    <Fragment>
      <Navbar />
      <div className="jobs-container banner-content-mt">
        <div
          className="banner-main"
          style={{ backgroundImage: `url(${constructionJob})` }}
        >
          <div className="banner-main-content-container">
            <span className="job-banner-content">
              Build Your Future with Us!
            </span>
            <span className="job-banner-content">
              Join Our Construction Crew Today
            </span>
          </div>
        </div>
        <div className="jobs-content-container">
          <h1 className="jobs-content-title">jobs</h1>
          <span className="jobs-content-description">
            Interested and qualified candidates should send their CV to{" "}
            <a
              href="https://www.gmail.com"
              target="_blank"
              rel="noreferrer"
              className="jobs-content-link"
            >
              amarc.engineering@gmail.com
            </a>
          </span>
          <div className="jobs-info-container">
            <div
              className="job-container"
              style={{ backgroundImage: `url(${architect})` }}
            >
              <span className="job-title">Architect</span>
              <div className="job-requirements-container">
                <span className="job-education">
                  Education: Bachelor's / Master's degree in Architecture.
                </span>
                <span className="job-experience">
                  Experience: 2 to 5 years of relevant experience in residential
                  projects
                </span>
              </div>
            </div>
            <div
              className="job-container"
              style={{ backgroundImage: `url(${civilEngineer})` }}
            >
              <span className="job-title">Civil Engineer</span>
              <div className="job-requirements-container">
                <span className="job-education">
                  Education: Bachelor's / Master's degree in Civil Engineering /
                  DAE Civil Engineer.
                </span>
                <span className="job-experience">
                  Experience: 2-3 Years Experience required
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};
export default Jobs;
