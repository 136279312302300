import "./ContactUs.css";
import Navbar from "../Navbar/Navbar.jsx";
import Footer from "../../components/Footer/Footer";
import { Fragment } from "react";
import { useMediaQuery } from "react-responsive";

const ContactUs = () => {
  const isTabletRange = useMediaQuery({ query: "(max-width: 750px)" });
  return (
    <Fragment>
      <Navbar />
      <div className="contact-us-container ">
        <div className="meet-us-container">
          <h1 className="content-main-header">meet us</h1>
          <div className="meet-us-iframe-container">
            <iframe
              width="100%"
              height="600"
              src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;coord=33.5691596, 73.1900983&amp;q=AMARC+Engineering+%26+Construction+Company&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"
              title="our-location"
            ></iframe>
            <br />
          </div>
        </div>
        <div className="get-in-touch-container">
          <h1 className="content-main-header">get in touch with us</h1>
          <span className="get-in-touch-with-us-description">
            Contact us today to get in touch and explore how we can assist you
            with your needs.
          </span>
          <div className="get-in-touch-with-us-content">
            <div className="get-in-touch-address">
              <h2 className="get-in-touch-address-title">address</h2>
              <div className="get-in-touch-address-container">
                <h3 className="get-in-touch-address-subtitle">head office</h3>
                <span className="get-in-touch-address-content">
                  <a
                    href="https://www.google.com/maps/place/AMARC+Engineering+%26+Construction+Company/@33.5691596,73.1900983,17z/data=!3m1!4b1!4m6!3m5!1s0x38dfefb93514756b:0x4cf7c43fb4ca16ee!8m2!3d33.5691552!4d73.1926732!16s%2Fg%2F11pfq8nvxs?entry=ttu"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Ground Floor, Plot No 59, Commercial 1, Naval Anchorage
                    Islamabad.
                  </a>
                </span>
                <h3 className="get-in-touch-address-subtitle">
                  contact numbers
                </h3>
                <span className="get-in-touch-address-content">
                  0300 2891288, 0345 5606814
                </span>
                <h3 className="get-in-touch-address-subtitle">email address</h3>
                <span className="get-in-touch-address-content">
                  <a
                    href="https://www.gmail.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    info@amarcengineering.co
                  </a>
                </span>
              </div>
            </div>
            <div className="get-in-touch-mail">
              <form className="get-in-touch-mail-form">
                <div className="get-in-touch-mail-row left-row">
                  <input
                    type="text"
                    className="get-in-touch-mail-left-content"
                    placeholder="Name *"
                    required={true}
                  />
                  <input
                    type="email"
                    className="get-in-touch-mail-left-content"
                    placeholder="Email *"
                    required={true}
                  />
                  <input
                    type="text"
                    className="get-in-touch-mail-left-content"
                    placeholder="Subject *"
                    required={true}
                  />
                  {isTabletRange ? (
                    <input
                      type="text"
                      className="get-in-touch-mail-right-content"
                      placeholder="Message *"
                      required={true}
                    />
                  ) : (
                    <button
                      type="submit"
                      className="get-in-touch-mail-left-content mail-button"
                    >
                      send message
                    </button>
                  )}
                </div>
                <div className="get-in-touch-mail-row">
                  {!isTabletRange ? (
                    <input
                      type="text"
                      className="get-in-touch-mail-right-content"
                      placeholder="Message *"
                      required={true}
                    />
                  ) : (
                    <button
                      type="submit"
                      className="get-in-touch-mail-left-content mail-button"
                    >
                      send message
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default ContactUs;
