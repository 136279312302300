import "./CardHolder.css";
import Card from "../Card/Card";
import { Fragment } from "react";
import { cardListData } from "../../data/data";

const CardHolder = () => {
  return (
    <Fragment>
      <div className="card-holder-header">
        <h1 className="content-main-header">Our Services</h1>
      </div>
      <div className="card-holder-container">
        {cardListData.map((card, id) => {
          return (
            <Card
              id={id}
              key={id}
              title={card.title}
              imageUrl={card.img}
              description={card.description}
            />
          );
        })}
      </div>
    </Fragment>
  );
};

export default CardHolder;
