import { ceoData } from "../../data/data";

const CEOContainer = () => {
  return ceoData.map((data, i) => (
    <div className="team-member-container" key={i}>
      <img src={data.image} alt={data.name} className="team-member-image" />
      <div className="team-member-info">
        <h3 className="team-member-name">{data.name}</h3>
        <span className="team-member-role">{data.role}</span>
      </div>
    </div>
  ));
};
export default CEOContainer;
